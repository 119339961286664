<template>
    <div v-if="isAdmin" class="px-2 container-with-fixed-bottom">
        <LoadingIcon :isLoading="isLoading" />
        <div class="inner-container">
            <v-container>
                <v-row no-gutters>
                    <v-col>
                        <p class="headline mb-2">Leveranciergegevens</p>
                    </v-col>
                </v-row>
                <v-form ref="form">
                    <v-row no-gutters>
                        <v-col cols="12" class="header px-0 pt-3">
                            <span class="header-title">{{getSupplierName(accountId)}}</span>
                            <v-spacer></v-spacer>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            Type
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <SupplierTypeDropdown :supplier="supplierType" @changed="supplierType = $event" />
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            Note
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <v-text-field flat
                                class="ma-0 pa-0"
                                :rules="[rules.required]"
                                required
                                v-model="note"></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </div>
        <div class="fixed-bottom-section">
            <v-container>
                <v-row>
                    <v-col class="12 pt-0 mx-2">
                        <v-btn color="primary" block @click="updateSupplier()">
                        Opslaan
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import LoadingIcon from '@/components/common/LoadingIcon';
import DataMixin from "@/mixins/data.mixin";
import RouteMixin from "@/mixins/route.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import SupplierTypeDropdown from '@/components/common/options/SupplierTypeDropdown';

export default {
    name: 'Leverancier',
    components: 
    {
        LoadingIcon,
        SupplierTypeDropdown
    },
    data: () => ({
        isLoading: false,
        supplierType: 0,
        note: "",
        accountId: ""
    }),
    computed: {
        ...mapState('authorizedModule', ['isAdmin']),
        ...mapState({
            suppliers: state => state.menuHelper.suppliers
        }),
    },
    mixins: [DataMixin, RouteMixin, MenuHelperMixin],
    mounted () 
    {
        this.accountId = this.$route.params.accountId;
        this.getSupplierBaseInformation();
    },
    methods: 
    {
        getSupplierBaseInformation()
        {
            this.$store.dispatch('supplierModule/getSupplierFromAccount', this.accountId)
            .then(data => {
                this.supplierType = data.supplierType,
                this.note = data.note,
                this.isLoading = false;
            })
            .catch(() =>
            {
                this.isLoading = false;
            });
        },
        updateSupplier(){
            const valid = this.$refs.form.validate();
            if(!valid)
            {
                return;
            }

            let request = {
                supplierType: this.supplierType,
                note: this.note,
                accountId: this.accountId
            }

            this.$store.dispatch('supplierModule/updateSupplier', request)
            .then(() => {
                this.isLoading = false;
                // redirect to suppliers.
                this.$store.commit('dialog/showDialog', 'Successfully updated supplier.');
                this.goToSuppliers();
            })
            .catch(() =>
            {
                this.isLoading = false;
                this.$store.commit('dialog/showDialog', 'Something went wrong. Updating supplier unsuccessful.');
            });
        }
    }
}
</script>
<style scoped>
.header
{
    display: inline-flex;
}

.edit-icon
{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.v-input
{
    height: 25px;
}
</style>